<template>
  <div>
    <b-container fluid>
      <b-row class="mt-5">
        <DualListBox
            :source='dualSource'
            :destination='dualDestination'
            label='name'
            @onChangeList='onChangeList'
        />
      </b-row>
      <b-row align-h="start" class="mt-2">
        <b-button size="lg" @click="addGroup" variant="primary" :disabled="!dualDestination.length">新增群組</b-button>
      </b-row>
      <b-row>
        <b-list-group>
          <b-list-group-item v-for="(i, idx) in groups" :key="i.id" class="d-flex flex-row align-items-center mt-4">
            <div class="mr-2">第 {{ i[0]['群組'] }} 組</div>
            <div v-for="(j, jdx) in i" :key="j.id">{{ j.name + ' ' + ((jdx + 1) === i.length ? '' : ',') + ' ' }} </div>
            <b-badge variant="primary" pill class="ml-2 mr-2 align-self-end">{{ i.length }}</b-badge>
            <b-button size="sm" variant="danger" @click="cancelGroup(i, idx)" style="margin-left: auto;">取消</b-button>
          </b-list-group-item>
        </b-list-group>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import DualListBox from 'dual-listbox-vue'
import { mapState, mapMutations } from 'vuex'
import 'dual-listbox-vue/dist/dual-listbox.css'
export default {
  name: 'groupconfig',
  components: {
    DualListBox
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState('pvStringFailure', [
      'dualSource',
      'dualDestination',
      'groups'
    ])
  },
  methods: {
    ...mapMutations('pvStringFailure', [
      'HANDLE_DUAL_LISTS',
      'ADD_GROUP',
      'CANCEL_GROUP'
    ]),
    onChangeList ({ source, destination }) {
      console.log('destination', destination)
      this.HANDLE_DUAL_LISTS({ source, destination })
    },
    addGroup () {
      this.ADD_GROUP()
    },
    cancelGroup (item, idx) {
      console.log(idx)
      this.CANCEL_GROUP({ item, idx })
    }
  }
}
</script>
<style lang='scss' scoped>

</style>
